<template>
	<a-modal title="店铺编辑" :width="1000" :visible="visible" :confirmLoading="confirmLoading" :destroyOnClose="true"
		@ok="handleSubmit" @cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<vue-scroll :ops="ops" style="height: 60vh; margin-top: 12px">
				<a-row :gutter="24">
					<a-col :md="12" :sm="24">
						<a-form :form="form">
							<a-form-item label="主体类型" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-select v-decorator="['type', { rules: [{ required: true, message: '请选择' }] }]"
									allow-clear style="width: 200px" placeholder="请选择">
									<a-select-option :value="1">个人</a-select-option>
									<a-select-option :value="2">个体工商户</a-select-option>
									<a-select-option :value="3">公司企业</a-select-option>
								</a-select>
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 1">
						<a-form :form="form">
							<a-form-item label="店铺名称" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['storeName', { rules: [{ required: form.getFieldValue('type') == 1 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 2">
						<a-form :form="form">
							<a-form-item label="店铺名称" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['businessCirclesStoreName', { rules: [{ required: form.getFieldValue('type') == 2 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 3">
						<a-form :form="form">
							<a-form-item label="店铺名称" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['companyStoreName', { rules: [{ required: form.getFieldValue('type') == 3 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24">
						<a-form :form="form">
							<a-form-item label="代理商邀请码" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入" v-model="agentCode" @input="inputAgentCode" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" @click="findCategoryList">
						<a-form :form="form">
							<a-form-item label="经营类目" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-select v-decorator="['categoryId', { rules: [{ required: true, message: '请选择' }] }]"
									allow-clear placeholder="请选择">
									<a-select-option v-for="(item, index) in categoryList" :key="index"
										:value="item.firstCategoryId">{{
                  item.firstCategoryName }}</a-select-option>
								</a-select>
							</a-form-item>
						</a-form>

					</a-col>
				</a-row>
				<a-row>
					<a-divider v-if="form.getFieldValue('type') == 2 || form.getFieldValue('type') == 3">营业执照信息
					</a-divider>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 2">
						<a-form :form="form">
							<a-form-item label="营业执照名称" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['businessCirclesBusinessLicenseName', { rules: [{ required: form.getFieldValue('type') == 2 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 2">
						<a-form :form="form">
							<a-form-item label="经营者名称" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['businessCirclesOperator', { rules: [{ required: form.getFieldValue('type') == 2 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 2">
						<a-form :form="form">
							<a-form-item label="营业执照注册号" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['businessCirclesBusinessLicenseNo', { rules: [{ required: form.getFieldValue('type') == 2 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="24" :sm="24" v-if="form.getFieldValue('type') == 2">
						<a-form :form="form">
							<a-form-item has-feedback label="营业执照" :labelCol="labelCol" :wrapperCol="wrapperCol">
								<div style="display: flex; flex-wrap: wrap;">
									<div v-for="(item, index)  in businessImageList" :key="index"
										style="margin: 0 15px 15px 0; width: 100px; height: 100px; position: relative">
										<img :src="item" v-if="item" style="width: 100px; height: 100px" />
										<img src="../../../../assets/delete.png"
											style="position: absolute; width: 30px; height: 30px; top: 35px; left: 35px; z-index: 99; cursor: pointer;"
											@click="deleteImageIndex(1, index)">
									</div>
									<div style="width: 100px; height: 100px">
										<a-upload style="margin: auto" name="avatar" accept=".png,.jpg,jpeg,.gif"
											list-type="picture-card" :show-upload-list="false"
											:customRequest="customUserRequest">
											<div v-if="businessImageList.length < 5">
												<a-icon :type="pictureLoading ? 'loading' : 'plus'" />
											</div>
										</a-upload>
									</div>
								</div>
							</a-form-item>
						</a-form>

					</a-col>

					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 3">
						<a-form :form="form">
							<a-form-item label="营业执照名称" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['companyBusinessLicenseName', { rules: [{ required: form.getFieldValue('type') == 3 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 3">
						<a-form :form="form">
							<a-form-item label="经营者名称" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['companyOperator', { rules: [{ required: form.getFieldValue('type') == 3 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 3">
						<a-form :form="form">
							<a-form-item label="营业执照注册号" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['companyBusinessLicenseNo', { rules: [{ required: form.getFieldValue('type') == 3 ? true : false, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="24" :sm="24" v-if="form.getFieldValue('type') == 3">
						<a-form :form="form">
							<a-form-item has-feedback label="营业执照" :labelCol="labelCol" :wrapperCol="wrapperCol">
								<div style="display: flex; flex-wrap: wrap;">
									<div v-for="(item, index)  in companyImageList" :key="index"
										style="margin: 0 15px 15px 0; width: 100px; height: 100px; position: relative">
										<img :src="item" v-if="item" style="width: 100px; height: 100px" />
										<img src="../../../../assets/delete.png"
											style="position: absolute; width: 30px; height: 30px; top: 35px; left: 35px; z-index: 99; cursor: pointer;"
											@click="deleteImageIndex(2, index)">
									</div>
									<div style="width: 100px; height: 100px">
										<a-upload style="margin: auto" name="avatar" accept=".png,.jpg,jpeg,.gif"
											list-type="picture-card" :show-upload-list="false"
											:customRequest="customerCompayRequest">
											<div v-if="companyImageList.length < 5">
												<a-icon :type="imageLoading ? 'loading' : 'plus'" />
											</div>
										</a-upload>
									</div>
								</div>
							</a-form-item>
						</a-form>

					</a-col>

					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 3 || form.getFieldValue('type') == 2">
						<a-form :form="form">
							<a-form-item label="营业执照类型" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-select v-decorator="['timeRange', { rules: [{ required: true, message: '请选择' }] }]"
									allow-clear placeholder="请选择">
									<a-select-option :value="1">期限内有效</a-select-option>
									<a-select-option :value="2">永久有效</a-select-option>
								</a-select>
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('type') == 3 || form.getFieldValue('type') == 2">
						<a-form :form="form">
							<a-form-item label="执照开始日期" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-date-picker v-model="beginTime" style="width: 150px" format="YYYY-MM-DD" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('timeRange') == 1">
						<a-form :form="form">
							<a-form-item label="执照结束日期" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-date-picker v-model="endTime" style="width: 150px" format="YYYY-MM-DD" />
							</a-form-item>
						</a-form>

					</a-col>
				</a-row>
				<a-row>
					<a-divider>实名认证信息</a-divider>
					<a-col :md="12" :sm="24">
						<a-form :form="form">
							<a-form-item label="身份证号" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['idNo', { rules: [{ required: true, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24">
						<a-form :form="form">
							<a-form-item label="真实姓名" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-input placeholder="请输入"
									v-decorator="['realName', { rules: [{ required: true, message: '请输入！' }] }]" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24">
						<a-form :form="form">
							<a-form-item has-feedback label="身份证正面" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1">
								<div style="width: 104px; height: 104px">
									<a-upload style="margin: auto" name="avatar" accept=".png,.jpg,jpeg,.gif"
										list-type="picture-card" :show-upload-list="false"
										:customRequest="customerUploadIdFront">
										<img :src="idFront" v-if="idFront" style="width: 100px; height: 100px" />
										<div v-else>
											<a-icon :type="frontLoading ? 'loading' : 'plus'" />
										</div>
									</a-upload>
								</div>
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24">
						<a-form :form="form">
							<a-form-item has-feedback label="身份证反面" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1">
								<div style="width: 104px; height: 104px">
									<a-upload style="margin: auto" name="avatar" accept=".png,.jpg,jpeg,.gif"
										list-type="picture-card" :show-upload-list="false"
										:customRequest="customerUploadIdBack">
										<img :src="idBack" v-if="idBack" style="width: 100px; height: 100px" />
										<div v-else>
											<a-icon :type="backLoading ? 'loading' : 'plus'" />
										</div>
									</a-upload>
								</div>
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24">
						<a-form :form="form">
							<a-form-item label="身份证类型类型" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-select v-decorator="['idNoType', { rules: [{ required: true, message: '请选择' }] }]"
									allow-clear placeholder="请选择">
									<a-select-option :value="1">期限内有效</a-select-option>
									<a-select-option :value="2">永久有效</a-select-option>
								</a-select>
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24">
						<a-form :form="form">
							<a-form-item label="开始日期" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-date-picker v-model="idBeginTime" style="width: 150px" format="YYYY-MM-DD" />
							</a-form-item>
						</a-form>

					</a-col>
					<a-col :md="12" :sm="24" v-if="form.getFieldValue('idNoType') == 1">
						<a-form :form="form">
							<a-form-item label="结束日期" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1" has-feedback>
								<a-date-picker v-model="idEndTime" style="width: 150px" format="YYYY-MM-DD" />
							</a-form-item>
						</a-form>

					</a-col>
				</a-row>

			</vue-scroll>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		giftEdit,
		giftDetail
	} from '@/api/modular/mallLiving/check/gift' //编辑和详情接口

	import {
		getUploadScreat
	} from '@/api/modular/mallLiving/uploadScreat'
	import {
		auditDetail,
		findCategoryListByAgentCode,
		editSettledText
	} from '@/api/modular/mallLiving/check/enterCheck'
	import COS from 'cos-js-sdk-v5'
	import moment from 'moment'
	export default {
		data() {
			return {
				loading: false,
				imageUrl: '',
				picture: '',
				allList: [],
				cos: null,
				ops: {
					vuescroll: {},
					scrollPanel: {},
					rail: {
						keepShow: true,
					},
					bar: {
						hoverStyle: true,
						onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
						background: '#333', //滚动条颜色
						opacity: 0.5, //滚动条透明度
						'overflow-x': 'hidden',
					},
				},
				pictureLoading: false,
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 4
					},
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 20
					},
				},
				labelCol_1: {
					xs: {
						span: 24
					},
					sm: {
						span: 8
					},
				},
				wrapperCol_1: {
					xs: {
						span: 24
					},
					sm: {
						span: 16
					},
				},
				parentList: [], //父级列表
				sort: '',
				id: '',
				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this),
				categoryList: [],
				businessImageList: [],
				companyImageList: [],
				imageLoading: false,
				beginTime: '',
				endTime: '',
				idBeginTime: '',
				idEndTime: '',
				idFront: '',
				frontLoading: false,
				idBack: '',
				backLoading: false,
				agentCode: '',
				customerId: '',
				realNameId: '',
				editId: ''
			}
		},

		watch: {

		},

		methods: {
			inputAgentCode(e) {
				this.form.setFieldsValue({
					categoryId: ''
				})
				this.categoryList = []
			},

			// 初始化方法
			edit(record) {
				let that = this;
				let msgData = sessionStorage.getItem("upload-screat-msg")
				if (msgData) {
					msgData = JSON.parse(sessionStorage.getItem("upload-screat-msg"))
					var etime = new Date().getTime()
					if (msgData.expiredTime * 1000 < etime + 20 * 60 * 1000) {
						this.getUploadScreat()
					} else {
						let data = {
							TmpSecretId: msgData.tmpSecretId,
							TmpSecretKey: msgData.tmpSecretKey,
							XCosSecurityToken: msgData.sessionToken,
							StartTime: msgData.startTime,
							ExpiredTime: msgData.expiredTime
						}
						that.cos = new COS({
							getAuthorization: function(options, callback) {
								callback(data)
							}
						})
					}
				} else {
					this.getUploadScreat()
				}
				//下面是正常的初始化
				auditDetail({
					id: record.id
				}).then((res) => {
					if (res.success) {
						let data = res.data
						that.agentCode = data.agentCode
						that.editId = data.id
						that.realNameId = data.realNameResult.id
						that.customerId = data.customerId
						let imageList = data.businessLicenseImages ? data.businessLicenseImages.split(',') : []
						that.companyImageList = data.type == 3 ? imageList : []
						that.businessImageList = data.type == 2 ? imageList : []
						that.idFront = data.realNameResult.idFront
						that.idBack = data.realNameResult.idBack
						that.confirmLoading = false
						that.idBeginTime = data.realNameResult.idBeginTime ? moment(data.realNameResult
							.idBeginTime) : ''
						that.idEndTime = data.realNameResult.idEndTime ? moment(data.realNameResult.idEndTime) : ''
						that.beginTime = data.beginTime ? moment(data.beginTime) : ''
						that.endTime = data.endTime ? moment(data.endTime) : ''
						that.findCategoryList()
						setTimeout(function() {
							that.form.setFieldsValue({
								type: data.type,
								categoryId: data.categoryId,
								realName: data.realNameResult.realName,
								idNo: data.realNameResult.idNo,

							})
						}, 100)
						setTimeout(function() {
							that.form.setFieldsValue({
								storeName: data.storeName,
								businessCirclesStoreName: data.storeName,
								companyStoreName: data.storeName,
								timeRange: data.endTime ? 1 : 2,
								idNoType: data.realNameResult &&data.realNameResult.idEndTime ? 1 : 2,
								companyBusinessLicenseName: data.type == 3 ? data
									.businessLicenseName : '',
								businessCirclesBusinessLicenseName: data.type == 2 ? data
									.businessLicenseName : '',
								businessCirclesOperator: data.type == 2 ? data
									.businessCirclesOperator : '',
								companyOperator: data.type == 3 ? data.businessCirclesOperator :
									'',
								businessCirclesBusinessLicenseNo: data.type == 2 ? data
									.businessLicenseNo : '',
								companyBusinessLicenseNo: data.type == 3 ? data.businessLicenseNo :
									''
							})
						}, 200)
					}
				}).finally((res) => {
					setTimeout(() => {
						that.confirmLoading = false
					}, 5000)
				})
				this.visible = true

			},

			deleteImageIndex(type, index) {
				if (type == 1) {
					this.businessImageList.splice(index, 1)
				} else if (type == 2) {
					this.companyImageList.splice(index, 1)
				}
			},



			findCategoryList() {
				findCategoryListByAgentCode({
					code: this.agentCode ? this.agentCode : ''
				}).then(res => {
					if (res.success) {
						this.categoryList = res.data
					}
				})
			},

			customerUploadIdBack(filedata) {
				let that = this;
				this.pictureLoading = true
				that.cos.putObject({
						// Bucket: 'chanbow-1309518477' /* 必须 */,
						Bucket: "cos-1311593864",
						Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */ ,
						Key: that.formatFileName() + '.' + filedata.file.name.split(".")[filedata.file.name.split(".")
							.length - 1] /* 必须 */ ,
						StorageClass: 'STANDARD',
						Body: filedata.file, // 上传文件对象
						onProgress: (progressData) => {},
					},
					(err, data) => {
						// 将上传后的封面进行路径拼接保存到本地
						if (data.Location) {
							let picture = 'https://' + data.Location
							that.idBack = picture
							that.$nextTick(function() {
								that.$forceUpdate()
							})
						}
						this.pictureLoading = false
					}
				)
			},

			customerUploadIdFront(filedata) {
				let that = this;
				this.pictureLoading = true
				that.cos.putObject({
						// Bucket: 'chanbow-1309518477' /* 必须 */,
						Bucket: "cos-1311593864",
						Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */ ,
						Key: that.formatFileName() + '.' + filedata.file.name.split(".")[filedata.file.name.split(".")
							.length - 1] /* 必须 */ ,
						StorageClass: 'STANDARD',
						Body: filedata.file, // 上传文件对象
						onProgress: (progressData) => {},
					},
					(err, data) => {
						// 将上传后的封面进行路径拼接保存到本地
						if (data.Location) {
							let picture = 'https://' + data.Location
							that.idFront = picture
							that.$nextTick(function() {
								that.$forceUpdate()
							})
						}
						this.pictureLoading = false
					}
				)
			},
			//时间处理函数
			onChange(time) {
				var date = new Date(time).getTime()
				// var year = date.getFullYear()
				// var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
				// var day = date.getDate()
				// var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
				// var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
				// var second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
				// return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
				return date;
			},

			handleSubmit() {
				this.confirmLoading = true
				this.form.validateFields((errors, values) => {
					if (!errors) {
						var realNameResult = {
							id: this.realNameId,
							idNo: values.idNo,
							realName: values.realName,
							idBack: this.idBack,
							idFront: this.idFront,
							idBeginTime: this.idBeginTime ? this.onChange(this.idBeginTime) : '',
							idEndTime: this.idEndTime ? this.onChange(this.idEndTime) : ''
						}
						console.log(realNameResult)
						var params = {
							beginTime: this.beginTime ? this.onChange(this.beginTime) : '',
							endTime: this.endTime ? this.onChange(this.endTime) : '',
							businessCirclesOperator: values.type == 1 ? '' : values.type == 2 ? values
								.businessCirclesOperator : values.companyOperator,
							businessLicenseImages: values.type == 1 ? '' : values.type == 2 ? this
								.businessImageList.join(',') : this.companyImageList.join(','),
							businessLicenseName: values.type == 1 ? '' : values.type == 2 ? values
								.businessCirclesBusinessLicenseName : values.companyBusinessLicenseName,
							businessLicenseNo: values.type == 1 ? '' : values.type == 2 ? values
								.businessCirclesBusinessLicenseNo : values.companyBusinessLicenseNo,
							categoryId: values.categoryId,
							type: values.type,
							storeName: values.type == 1 ? values.storeName : values.type == 2 ? values
								.businessCirclesStoreName : values.companyStoreName,
							agentCode: this.agentCode,
							customerId: this.customerId,
							id: this.editId,
							realNameResult: realNameResult

						}
						console.log(params)
						// 调取编辑接口
						editSettledText(params).then((res) => {
							if (res.success) {
								this.$message.success("商家信息编辑成功")
								this.$emit("ok", values)
								this.confirmLoading = false
								this.handleCancel()
							} else {
								setTimeout(() => {
									this.confirmLoading = false
								}, 600)
							}
						})
					} else {
						this.confirmLoading = false
					}
				})
			},
			handleCancel() {
				this.visible = false //关闭对话框
				this.confirmLoading = false
				this.form.resetFields() //重置表单
			},
			//图片上传，cos
			getUploadScreat() {
				let that = this
				getUploadScreat().then((res) => {
					that.uploadOption = res.data
					let data = {
						TmpSecretId: res.data.tmpSecretId,
						TmpSecretKey: res.data.tmpSecretKey,
						XCosSecurityToken: res.data.sessionToken,
						StartTime: res.data.startTime,
						ExpiredTime: res.data.expiredTime
					}
					sessionStorage.setItem("upload-screat-msg", JSON.stringify(res.data))
					that.cos = new COS({
						getAuthorization: function(options, callback) {
							callback(data)
						}
					})
				})
			},
			formatFileName() {
				var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
					a = t.length,
					n = "";

				for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a));
				return n + new Date().getTime()
			},
			//自定义图片上传实现
			customUserRequest(filedata) {
				let that = this;
				this.pictureLoading = true
				that.cos.putObject({
						// Bucket: 'chanbow-1309518477' /* 必须 */,
						Bucket: "cos-1311593864",
						Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */ ,
						Key: that.formatFileName() + '.' + filedata.file.name.split(".")[filedata.file.name.split(".")
							.length - 1] /* 必须 */ ,
						StorageClass: 'STANDARD',
						Body: filedata.file, // 上传文件对象
						onProgress: (progressData) => {},
					},
					(err, data) => {
						// 将上传后的封面进行路径拼接保存到本地
						if (data.Location) {
							let picture = 'https://' + data.Location
							that.businessImageList.push(picture)
							that.$nextTick(function() {
								that.$forceUpdate()
							})
						}
						this.pictureLoading = false
					}
				)
			},
			customerCompayRequest(filedata) {
				let that = this;
				this.imageLoading = true
				that.cos.putObject({
						// Bucket: 'chanbow-1309518477' /* 必须 */,
						Bucket: "cos-1311593864",
						Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */ ,
						Key: that.formatFileName() + '.' + filedata.file.name.split(".")[filedata.file.name.split(".")
							.length - 1] /* 必须 */ ,
						StorageClass: 'STANDARD',
						Body: filedata.file, // 上传文件对象
						onProgress: (progressData) => {},
					},
					(err, data) => {
						// 将上传后的封面进行路径拼接保存到本地
						if (data.Location) {
							let picture = 'https://' + data.Location
							that.companyImageList.push(picture)
							that.$nextTick(function() {
								that.$forceUpdate()
							})
						}
						this.imageLoading = false
					}
				)
			}
		},
	}
</script>
<style scoped>
	.flex {
		display: flex;
	}

	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}
</style>
